import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/next/font/google/target.css?{\"path\":\"src/config/fonts.ts\",\"import\":\"Orbitron\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-orbitron\"}],\"variableName\":\"fontOrbitron\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/next/font/google/target.css?{\"path\":\"src/config/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"fontMontserrat\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src989532146/src/craft-stadium/frontend/user/node_modules/next/font/google/target.css?{\"path\":\"src/config/fonts.ts\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-noto-sans-jp\"}],\"variableName\":\"fontNotoSansJP\"}");
