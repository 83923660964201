import { Button } from "@nextui-org/button"
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card"
import { Image } from "@nextui-org/image"
import Link from "next/link"
import { FC } from "react"
import { RiExternalLinkLine } from "react-icons/ri"

import { IconTextItem } from "../[hackathonId]/_components/IconTextItem"

import { TRaidSummary } from "@/api/raid"
import { PATH, RAID_TABS } from "@/constants/path"
import { formatHackathonCardDate } from "@/lib/formatHackathonDate"

type Props = {
  hackathon: TRaidSummary
}

export const ParticipatingHackathonCard: FC<Props> = ({ hackathon }) => {
  return (
    <Card className="max-w-xl dark:bg-lightBackground" shadow="sm" radius="sm">
      <CardHeader className="absolute z-10 flex h-[148px] w-full flex-col justify-end bg-gradient-to-t from-black/70 via-black/40 to-black/10 p-4 sm:h-[120px]">
        <h3 className="line-clamp-2 text-lg font-bold text-white">
          {hackathon.info.title}
        </h3>
        <div className="flex w-full gap-3 text-xs text-white/90">
          <IconTextItem
            iconInfo={{ name: "RiGlobalFill", isRiIcon: true }}
            text="オンライン"
          />
          <IconTextItem
            iconInfo={{
              name: "RiMoneyDollarCircleFill",
              isRiIcon: true,
            }}
            text={hackathon.info.prize.total}
          />
        </div>
      </CardHeader>

      <Image
        removeWrapper
        alt={hackathon.info.title}
        className="z-0 h-[148px] w-full rounded-sm object-cover sm:h-[120px]"
        src={hackathon.info.imageUrl}
      />

      <CardBody className="gap-3 px-4 py-3">
        {/* イベント日程 */}
        <div className="flex flex-col gap-2 text-xs sm:flex-row sm:items-center sm:justify-between">
          <IconTextItem
            iconInfo={{ name: "RiFlag2Fill", isRiIcon: true }}
            text={formatHackathonCardDate(hackathon.info.eventDate.kickoff)}
            description="キックオフ"
          />
          <IconTextItem
            iconInfo={{ name: "RiFileUploadFill", isRiIcon: true }}
            text={formatHackathonCardDate(hackathon.info.eventDate.raidEnd)}
            description="成果物提出 締切日"
          />
          <IconTextItem
            iconInfo={{ name: "RiTrophyFill", isRiIcon: true }}
            text={formatHackathonCardDate(hackathon.info.eventDate.demoDay)}
            description="デモデイ"
          />
        </div>

        <div className="grid grid-cols-2 gap-2">
          <Link
            href={PATH.SERVICE.getRaidDetailWithTab(
              hackathon.info.id,
              RAID_TABS.OVERVIEW,
            )}
            className="w-full"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button
              variant="faded"
              color="default"
              className="w-full"
              size="sm"
              endContent={<RiExternalLinkLine />}
            >
              ハッカソン概要
            </Button>
          </Link>
          <Link
            href={PATH.SERVICE.getRaidDetailWithTab(
              hackathon.info.id,
              RAID_TABS.RULES,
            )}
            className="w-full"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button
              variant="faded"
              className="w-full"
              size="sm"
              endContent={<RiExternalLinkLine />}
            >
              ルール
            </Button>
          </Link>
        </div>
      </CardBody>

      <CardFooter className="px-4 pb-4 pt-0">
        <Link
          href={PATH.SERVICE.getHackathonPlayerParticipateInfo(
            hackathon.info.id,
          )}
          className="w-full"
        >
          <Button color="primary" className="w-full py-2">
            <div className="flex flex-col items-center">
              <span className="font-bold">出場プレイヤー向けページへ</span>
              <span className="pb-1 text-[10px] leading-none">
                チーム管理・成果物提出はこちら
              </span>
            </div>
          </Button>
        </Link>
      </CardFooter>
    </Card>
  )
}
